import React, {useState, useEffect} from "react";
// import TourList from "./TourList";
import { useSelector } from "react-redux";
import { simpleRequest } from "../../newForms2/simpleRequest";
import TourEscort from "./TourEscortDetails";
import TourEscortNew from "../../newForms2/tours/TourEscortNew";
import Spinner from "../Spinner";
import { inside } from "../../config";

const TourEscortArea = ({tourEscorts, setTourEscorts, account, setAccount}) => {
    inside()
    
    const [isLoading, setIsLoading] = useState(true);
    
    // const [account, setAccount] = useState(null);
    const currentUser = useSelector(st => st.currentUser);
    const currentCruise = useSelector(st => st.currentCruise);
    inside()
    useEffect(() => {
        const fetch = async() => {
            if(!currentUser?.username)return;
            const request = {url:`tourescorts/user/${currentUser.username}`}
            const _te = (await simpleRequest(request)).data?.tourEscorts || [];
            console.log('inside useEffect', {request, _te});
            setTourEscorts([..._te]);
            setIsLoading(false)
        };
        if(isLoading)fetch()
    }, [isLoading, currentUser, setTourEscorts]);

    useEffect(() => {
        console.log('inside useEffect2', {tourEscorts})
        setAccount(tourEscorts.find(te => te.companyHandle === currentCruise.companyHandle))
    }, [tourEscorts, currentCruise])

    if(isLoading)return <Spinner/>

    const {companyHandle} = currentCruise;
    const allowed = ["developer", "admin"].includes(currentUser?.userStatus) 
            || currentUser?.userStatus?.includes(companyHandle);
    console.log({companyHandle, tourEscorts,  allowed});
    console.log({companyHandle, account, tourEscorts,  allowed});

    if(!allowed) return null;
    return(
        <div className = "my-3">
            {!!account && <TourEscort account={account} setTourEscorts={setTourEscorts}/>}

            {!account && <TourEscortNew setTourEscorts={setTourEscorts} />}
        </div>
    )
};
export default TourEscortArea;
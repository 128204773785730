import React, {useState, useEffect} from "react";
import {Row, Col, Spinner, Button} from 'react-bootstrap';
import { translate } from "../../components/Language/Translate";
import { StyledDivWithBorder } from "../../components/styledComponents/divs";
import { simpleRequest } from "../simpleRequest";
import TourSchema from "./TourSchema";
import FormRowFields from "../FormRows/FormRowFields";
import { useSelector } from "react-redux";
import { getStringTour, getTemplates, templateKeys } from "./helpers";
import TemplateList from "./TemplateList";

const TourEdit = ({id, action, backFunc, setTours}) => {
    // console.log('inside TourNew', action, backFunc)
    const [templates, setTemplates] = useState([]);
    const [showTemplates, setShowTemplates] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const currentCruise = useSelector(st => st.currentCruise);

    useEffect(() => {
        const initialData = async() => {
            const tour = (await simpleRequest({url:`tours/${id}`})).data?.tour;
            const _fields = new TourSchema().fields;
            delete _fields.analyseField;
            const _vars = {};
            Object.keys(_fields).forEach(k => _vars[k] = tour[k]);
            setVariables({..._vars});
            setFieldSettings({..._fields});
        }
        const fetch = async() => {
            await initialData();
            setTemplates([...await getTemplates(currentCruise, action)]);
            setIsLoading(false);
        }
        if(isLoading)fetch()
    }, [isLoading, action, currentCruise, id]);

    if(isLoading)return <Spinner/>

    const handleClick = e => {
        const _template = templates.find(t => t.id === +e.target.parentElement.id);
        const newObj = {};
        templateKeys.forEach(k => newObj[k] = _template[k]);
        setVariables({...variables, ...newObj});
    };
    const handleSubmit = async () => {
        const data = {...variables};
        const templateExists = !!templates.find(t => (getStringTour(t) === getStringTour(data)));
        const tour = (await simpleRequest({url:`tours/${id}`, method:"patch", data})).data?.tour;
        setTours(tours => [...tours.filter(t => t.id !== id), tour]);
        if(!templateExists){
            const {companyHandle, destinationId} = tour;
            const dataTemplate = {companyHandle, destinationId};
            templateKeys.forEach(k => dataTemplate[k] = variables[k]);
            const tourTemplate = (await simpleRequest({url:"tourtemplates", method:"post", data:dataTemplate})).data?.tourTemplate;
            if(!tourTemplate)return;
            setTemplates(templates => [...templates, tourTemplate]);
        };
        backFunc();
    };

    const rowSet = {
        lg: [[2,10], [2,2,5,2], [4,4,4], [12]], 
        sm: null,
    };

    const props = {
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError,
        setServerError,
        rowSet
      };

    //   // console.log({props});

    return(
        <StyledDivWithBorder>
            <h4>{translate("Edit Tour")} </h4>
            <Button className="btn, btn-outline-dark" onClick={() => setShowTemplates(!showTemplates)}>{translate("Templates")}</Button>
            {showTemplates && <TemplateList templates={templates} handleClick={handleClick} setTemplates={setTemplates}/>}
            <FormRowFields props= {props}/>
            <Row>
                <Col>
                    <button className="btn btn-info" onClick={handleSubmit}>{translate("Update")}</button>
                </Col>
                <Col>
                    <button className="btn btn-info" onClick={backFunc}>{translate("Cancel")}</button>
                </Col>
            </Row>
        </StyledDivWithBorder>
    )

};
export default TourEdit;
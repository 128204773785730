import styled from 'styled-components';

export const StyledDiv = styled.div`
    margin-top:10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: rgba(255,255,255,.5)
`;
export const StyledDivWithBorder = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
    padding 10px;
    border:1px solid grey;
    border-radius:5px;
    background-color:rgba(255,255,255,.3)
`;
import React, { useState } from "react";
import {useSelector} from "react-redux";
import {translate} from '../Language/Translate';
import AddProfilePhoto from "./AddProfilePhoto";
import {Image} from 'react-bootstrap';
import {Link} from "react-router-dom";
// import { formatText } from "../../helpers/text";



const FirstStepsLoggedIn = () => {
    const currentUser = useSelector(st => st.currentUser);
    const hasPhoto = currentUser.photoUrl ? true: false;
    const photoIsConfirmed = currentUser.photoConfirmed;
    const [stage, setStage] = useState('startLoggedIn');
    // console.log({currentUser}, hasPhoto, currentUser.photoUrl);


    const FirstStepsLoggedInOptions = () => (
        <div className="text-center my-3" style={{backgroundColor:"rgba(255,255,255,.5"}}>
                <h3>{`${translate("Welcome")} ${currentUser.firstName} ${currentUser.lastName} !`}</h3>
                
                <div className = "p-3">
                    {!hasPhoto && <h5>{translate("Your profile is not complete without a profile photo.")}</h5>}
                    {!hasPhoto &&<button className="btn btn-outline-dark" onClick={() => setStage("addPhoto")}>{translate("Add Profile Photo")}</button>} 
                    {hasPhoto && <Image src = {currentUser.photoUrl} style={{maxWidth:"20%"}}/>} 
                    {hasPhoto && !photoIsConfirmed && <p>{translate("Your profile photo is being checked.")}</p>}
                </div>

                <div className = "p-3">
                    <h4>{translate("Please start here")}:</h4>
                    <h5>{translate('This is where you will find your cruise and the friends and acquaintances you have met or will meet there.')}</h5>
                    <Link to={"/start"} className="btn btn-outline-dark mt-3"><b>{translate("Discover Cruisefriends.net")}</b></Link>
                </div>
        </div>
    );
    return(
        <>
            {stage === "startLoggedIn" && <FirstStepsLoggedInOptions/>}
            {stage === "addPhoto" && <AddProfilePhoto setStage={setStage}/>}
        </>
    )
};
export default FirstStepsLoggedIn;
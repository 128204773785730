export const agbText = [

    "Diese Seite ist ein Angebot an Reisende und Unternehmen.",
    "Es entsteht kein Nutzungsvertrag.",
    "Einen Anspruch auf die Nutzung der Seite gibt es weder für Reisende noch für Unternehmen.",
    "",
    "Für Unternehmen:",
    "Zur Zeit gibt es noch keine Möglichkeit, Werbung für Reisen auf dieser Seite zu schalten, oder sonstige Verträge abzuschließen.", 
    "Bitte wenden Sie sich bei Fragen direkt an den Betreiber (siehe Impressum).", 
    "Unternehmen können hier ihre Reisen einpflegen.  Bitte wenden Sie sich an den Betreiber der Seite, um die Einzelheiten zu besprechen.", 
    "Es gibt nichts, was die Lust auf eine Kreuzfahrt mehr triggert als die Erinnerung an eine vergangene Reise. Daher legt Cruisefriends.net großen Wert darauf, dass auch frühere Reisen zur Verfügung gestellt werden.", 
    "Den nötigen Zugang erhalten die Unternehmen direkt vom Betreiber der Seite. Sie können innerhalb ihres Unternehmens Admins unterschiedlicher Hierarchien und mit verschiedenen Rechten benennen. Die Anleitung dafür gibt es dann mit dem Zugang.", 
    "",
    "Demnächst:",
    "Es können demnächst Reisen gezielt beworben werden (z.B. als Last-Minute-Angebot). Diese Anzeigen können dann direkt auf der Detailseite der betreffenden Reise geschaltet werden.", 
    "",
    "",
    "",
    "Nutzungsregeln für Benutzer:",
    "1.	Die Nutzung der Seite ist für Benutzer kostenlos.",
    "2.	Die Daten werden vertraulich behandelt und nicht an Dritte weitergegeben.  Nach der Löschung von Daten bleiben sie nur so lange gespeichert, wie es von den Gesetzen verlangt wird und werden anschließend automatisch gelöscht.  Für genaue Informationen lesen Sie bitte die Datenschutzerklärung. Den Link finden Sie am Ende der Seite.",
    "3.	Wenn ein User einer Reise beigetreten ist, kann er die anderen Teilnehmer dieser Reise sehen und kontaktieren, und ist selbst auch für die anderen Reisenden und die Schiffscrew sichtbar.", 
    "4.	Der User stimmt zu, dass er durch die Benutzung dieses Dienstes für die anderen Reisenden sichtbar ist. Nur Benutzername, 'Angezeigter Name' und das Foto können von anderen Benutzern gesehen werden. Vor- und Nachname, sowie die Emailadresse bleiben für andere Benutzer verborgen.", 
    "5.	Jeder User kann sich nach dem Registrieren und der Bestätigung der Emailadresse einloggen. Es wird empfohlen, ein sicheres Passwort zu verwenden. Cruisefriends.net übernimmt keine Verantwortung für gehackte Passwörter. Benutzername (sofern er nicht schon vergeben ist) und Passwort sind frei wählbar. Lediglich die Mindestlänge von 8 Buchstaben ist vorgegeben. ", 
    "6.	Beleidigungen und Diskriminierungen jeder Art sind verboten.", 
    "7.	Hacker sind auf dieser Seite unerwünscht.",
    "8. Cruisefriends.net ist dafür gedacht, dass sich Gäste und Crew einer Reise miteinander vernetzen können, und gemeinsame Reisen zu finden. Ferner gibt es Informationen zum Reiseverlauf  und Möglichkeiten für eigene Notizen (Reisetagebuch).", 
    "9.	Cruisefriends.net dient Unterhaltungszwecken, und ist nicht für besonders sensible Daten geeignet. Cruisefriends übernimmt keine Haftung, falls es zu einem Datenleck kommen sollte, auch wenn dieser Fall unwahrscheinlich ist.", 
    "10. An der Pinnwand kann jeder User Texte schreiben oder Fotos und Dokumente verlinken. Der User ist für die Wahrung der Persönlichkeitsrechte verantwortlich, sofern auf den Fotos Personen erkennbar sind. Cruisefriends.net übernimmt hier keine Verantwortung. Von der Schiffscrew können Fotos und Beiträge gelöscht werden.", 
    "11. Die Pinnwand ist dafür da, Beiträge zu schreiben, die für andere User nützlich sein können, und nicht um 'Dampf abzulassen'. Wer das vorhat, sollte das tun, aber bitte woanders.", 
    "12. Auf dieser Plattform können keine Reisen gebucht werden, es gibt aber demnächst externe Links zu Reiseveranstaltern.", 
    "13. Der User verpflichtet sich mit seiner Anmeldung, die Plattform in diesem Sinne zu nutzen.", 
    "14. Bei Verstößen wird der Account ohne weitere Diskussion gelöscht und die Emailadresse gegen eine neue Anmeldung gesperrt.", 
    "", 
    "", 


];
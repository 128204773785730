import React from "react";
// import SelectField from "./Fields/SelectField";
import TextField from "./Fields/TextField";
import TextArea from "./Fields/TextArea";
import CheckBox from './Fields/CheckBox';
// import FieldValidation from './Validation/FieldValidation'
// import UploadAreaSingle from "./upload/UploadAreaSingle";
// import RadioField from "./Fields/RadioField";
// import { translate } from "../components/Language/Translate";
// import MultiCheck from "./destinations/MultiCheck";
// import StarSet from "./Fields/StarSet";
// import GeolocationField from "./Fields/GeolocationField";
import CurrencyField from "./Fields/CurrencyField";
import PercentField from "./Fields/PercentField";
import DecimalField from "./Fields/DecimalField";
import FieldValidation from "./Fields/validation/FieldValidation";
import SelectField from './Fields/SelectField'
import TextFieldWithSelect from "./Fields/TextFieldWithSelect";
// import TextFieldWithButton from "./Fields/TextFieldWithButton";
import TextFieldWithNowButton from "./Fields/TextFieldWithNowButton";
import IntegerField from "./Fields/IntegerField";



const FormField = ({props}) => {
  // console.log(props);
  const {
    name,
    fieldSettings, 
    variables, 
    setVariables,
    fieldErrors, 
    setFieldErrors, 
    serverError,
    setServerError
  } = props;
  // console.log(name, variables[name], fieldSettings[name]);

  const fSettings = fieldSettings[name];

  const handleChange = e => {
    setFieldErrors({...fieldErrors, [e.target.name]:""});
    setVariables({...variables, [e.target.name]:e.target.value});
    setServerError && serverError && (() => setServerError(""))();
  };
  
  const validateField = (e) => {
    const {name, value} = e.target;
    const validation = new FieldValidation(name, value, fieldSettings, variables);
    const objValid = validation.isValid()
    if(objValid.valid){
      const newFieldErrors = {...fieldErrors};
      delete newFieldErrors[name];
      setFieldErrors({...newFieldErrors});
    }else{
      setFieldErrors({...fieldErrors, [name]: objValid.errMsg});
    };
};
  
const newProps = {
    ...props,
    handleChange,
    validateField,
  };
//   const keys = Object.keys(variables);
  // console.log({keys});
  // const kinds = Array.from(new Set(fieldSet))
    const {fieldType, note, note2} = fSettings;
    switch (fieldType) {
        case "text":
          return <TextField key={name} name={name} props={newProps} />
        case "number":
          
          // return <Integ key={name} name={name} props={newProps} />
          return <IntegerField key={name} name={name} props={newProps} />
        case "select":
          return <SelectField key={name} name={name} props={newProps} />
        case "textarea":
          return <TextArea key={name} name={name} props={newProps}/>
        case "checkBox":
        case "boolean":
          return <CheckBox name={name} key={name} props={newProps} />
        case "textWithSelect":
          return <TextFieldWithSelect name={name} key={name} props={newProps}/>
        // case "radio":
        //   return <RadioField name={name} key={name} props={newProps} />
        // case "upload":
        //   return <UploadAreaSingle key={name} name= {name} props={newProps}/>
        // case "multicheck":
        //   return <MultiCheck key ={name} name={name} props={newProps}/>
        // case "starSet":
        //   return <StarSet key = {name} name={name} props={newProps}/>
        // case "geolocation":
        //   return <GeolocationField key = {name} name={name} props={newProps} />
        case "decimal":
          return <DecimalField key={name} name={name} props={newProps}/>
        case "textWithButton":
          return <TextFieldWithNowButton key={name} name={name} props={newProps}/>
        case "percent":
          return <PercentField key={name} name={name} props={newProps}/>
        case "currency":
          return <CurrencyField key={name} name={name} props={newProps}/>
        default:
          return <p key={name}>undefined: {name}, {fieldSettings[name].fieldType}</p>;
    }
//   return(
//     <>  
//         {serverError && <div className="p-2" ><p className = "text-danger" >{translate(serverError)}</p></div>}

    	
        
      
//         {serverError && <div className="p-2" ><p className = "text-danger" >{translate(serverError)}</p></div>}
//       </>
//   )
};

export default FormField;
export const tourReports = {
    tablename: "tourReports",
    text: 
    
    `name	label	type	fieldType	sqlType	maxLength	initialValue	options	props	col
id	ID	number	integer	SERIAL PRIMARY KEY, INTEGER				noForm noSchema	
tourId	Ausflug auswählen	number	textWithSelect			0		requiredSchemaNew	12
startTime	Start Time	time	no	TIMESTAMP		null		noForm	
key	Key	string	no			uuid.v4()		noForm	
name	Titel des Ausflugs (optional, nur für die eigenen Daten)	string	text		25				8
busNr	Tour-/Busnummer	number	number			0			2
guestsCount	Anzahl Gäste	number	number			0			2
remarks	Operative Anmerkungen zum Timing und besondere Vorkommnisse	string	textarea						12
difficulty	Schwierigkeitsgrad des Ausflugs	string	select		15		, sehr leicht, leicht, moderat, anspruchsvoll, aktiv		3
description	Nähere Erläuterungen zum Schwierigkeitsgrad	string	textarea						9
localGuideName	Name örtlicher Guides	string	text		20				3
localGuideQuality	Beurteilung	string	text		20				3
localGuideLanguageAndLevel	Sprache & Level	string	text		20				4
headsets	Headsets im Einsatz 	boolean	checkBox			false			2
restaurantName	Restaurant mit Namen	string	text		30				6
restaurantQuality	Qualität	string	select		15		, gut, befriedigend, schlecht		6
tourQuality	Schiffsseitige Bewertung des Ausflugs	string	select		10		, gut, mittel, schlecht		6
contentment	Erwartung bzw. Zufriedenheit der Gäste	string	select		35		, erfüllt, mit Einschränkungen erfüllt, nicht erfüllt		6
proposals	Verbesserungsvorschläge	string	textarea						12
changes	Ausschreibung in der Broschüre bzw. Änderungen	string	textarea						12
`}


import React, { useEffect } from "react";
import {Form} from "react-bootstrap";


const IntegerField = ({name, props}) => {
    // console.log('Integerfield', {name, props});
    const {
        variables,
        setVariables,
        handleChange,
        validateField,
        fieldSettings, 
        fieldErrors, 
        } = props
        const {label, required, autoFocus, toolTip, placeholder, type, visible, disabled} = fieldSettings[name];
        const value = variables[name];

    // useEffect(() => {if(value === undefined) setVariables(vars => {return{...vars, [name]: 0}})}, [value, name, setVariables]);

    const handleChangeLocal = e => setVariables(vars => {return{...vars,[name]:+e.target.value}});
    

    if (visible === false) return null;
    return(
        <Form.Group size="lg" controlId={name} >
            {/* <Form.Label style={{color:"#1a4a70"}} className ="pt-1 px-1" >{label}{required? ' * ':' '} */}
            <Form.Label  className ="pt-1 px-1" >{label}{required? ' * ':' '}
            { fieldErrors[name]?<small className="text-danger" > - {fieldErrors[name]}</small>:null}
            </Form.Label>
            <Form.Control 
                style = {fieldErrors[name] ? {border:"2px solid red", backgroundColor:"rgb(255,235,235)"}:{}}
                name={name} 
                disabled={disabled}
                type="number" 
                key={name} 
                autoFocus={autoFocus}
                value={value}
                required = {required}
                // placeholder={placeholder}
                placeholder='integer'
                onChange={e => handleChangeLocal(e)} 
                onBlur={e => validateField(e)}
            />
            {toolTip?<p><small className="text-black p-1" >{toolTip}</small></p>:null}
        </Form.Group>
    )
}
export default IntegerField;

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../components/Language/Translate";
import FieldValidation from "./validation/FieldValidation";



    
const TextFieldWithSelect = ({props, minChars=2}) => {
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [displayed, setDisplayed] = useState('')
    const {name, setVariables, variables, fieldSettings, fieldErrors, setFieldErrors} = props;
    const {validation, label, required, toolTip, options} = fieldSettings[name];
    const value = variables[name];
    const style = fieldErrors[name] ? {border:"2px solid red", borderRadius:"5px"} : {};
    const ErrorSpan = () => <span className = "text-danger">{` - ${fieldErrors[name]}`}</span>;
    
    const handleClick = e => setVariables({...variables, [name]:+e.target.id})


    const handleChange = e => {
        console.log(e.target);
        setDisplayed(e.target.value);
        setFilteredOptions(options.filter(o => o.value.toLowerCase().includes(e.target.value.toLowerCase())));
    };
    const validateField = e => {
        
        const validObj = new FieldValidation(name, value, fieldSettings, variables).isValid();
        if(!validObj.valid)setFieldErrors({...fieldErrors, [name]:validObj.errMsg});
    };
    // console.log({props, required})
    console.log({variables});

    if(variables[name]){
        console.log(variables[name], "wie kann das sein")
        const selected = options.find(o => o.key === variables[name]) || options[0];
        console.log(options, selected, variables[name])
        return(<>
                    <p onClick = {() => setVariables({...variables, [name]:0})}><b>{selected.value}</b></p>
                </>)
    }
    return(
        <>
        <Form.Group>
        <Form.Label>{translate(label) + `${required ? ` *` : ''}`}
            {fieldErrors[name] && <ErrorSpan/>}
        </Form.Label>
        <Form.Control
            style={style}
            name={'displayed'}
            value={displayed}
            type="text"
            onChange={e => handleChange(e)}
            required = {required}
            onBlur={e => validateField(e)}
            placeholder={'Please insert at least 2 chars'}
        />
        {toolTip && <p><small>{toolTip}</small></p>}
        </Form.Group>
        <ul>
            {filteredOptions.map(o => <li key= {o.key} id={o.key} onClick={e => handleClick(e)}>{o.value}</li>)}
        </ul>
        </>
        
    );
};
export default TextFieldWithSelect;